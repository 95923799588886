import '../css/BoardTile.css';

function BoardTile(props) {
  return (
    <div className="board-tile" onClick={props.onClick} key={props.key}>
      <span className={`board-tile-text ${props.category ? 'board-tile-text-category' : ''}`}>
        {props.disabled ? '' : props.value}
      </span>
    </div>
  )
}

export default BoardTile;
