import React, { useState, useEffect, useRef } from 'react';
import '../css/PlayerLobby.css';
import {ROOM_CODE_LENGTH, PLAYER_NAME_MAX_LENGTH, NUM_FONTS} from '../constants.js'
import Podium from './Podium'
import { v4 as uuid } from 'uuid';
import {EVENT_TYPES, sendEvent} from '../GameEvent'


import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

function PlayerClient(props) {
  let [playerId] = useState(()=>{
    let id = localStorage.getItem('playerId') ?? uuid()
    localStorage.setItem('playerId', id)
    return id
  })
  let [name, setName] = useState(localStorage.getItem('name') ?? '')
  let [roomCode, setRoomCode] = useState(localStorage.getItem('roomCode') ?? '')
  let [font, setFont] = useState(localStorage.getItem('font') ?? 1)
  let [isTappingBuzzer, setIsTappingBuzzer] = useState(false)
  let codeInput = useRef(null)

  useEffect(()=>{
    codeInput.current.focus()
  },[codeInput])

  async function onJoinGameClick(isHost=false) {
    firebase.analytics().logEvent(`player__${isHost?'host_':''}join_game_click`, {playerId: playerId, roomCode})

    if (!name.length && !isHost) {
      alert('Please enter a name')
      return
    }
    if (roomCode.length < ROOM_CODE_LENGTH) {
      alert(`Please enter a ${ROOM_CODE_LENGTH}-digit game code`)
      return
    }

    // look for most recent game of gameCode within last 24 hours that hasn't ended
    let yesterday = new Date(Date.now() - 24 * 60 * 60 * 1000)
    let gameQuery = firebase.firestore().collection('games')
                .where('roomCode', '==', roomCode)
                .where('endedAt', '==', null)
                .where('createdAt', '>', firebase.firestore.Timestamp.fromDate(yesterday))
                .orderBy('createdAt', 'desc')
                .limit(1)


    let gameSnapshot = await gameQuery.get().catch((e)=>{
      console.error('Error looking up game', e)
    })

    if (!gameSnapshot.docs.length) {
      alert('Game not found')
    } else {
      if (isHost){
        joinHostToGame(gameSnapshot.docs[0])
      } else {
        joinPlayerToGame(gameSnapshot.docs[0])
      }
    }
  }

  async function joinHostToGame(gameDoc) {
    let _host = gameDoc.data().host
    if (_host && _host !== playerId) {
      alert('Host has already joined')
      return
    }

    let isRejoiningHost = _host && _host === playerId
    if (!isRejoiningHost && gameDoc.data().startedAt) {
      alert('Game has already started')
      return
    }

    let _gameId = gameDoc.id
    firebase.firestore().collection('games').doc(_gameId).set({...gameDoc.data(), host: playerId})
      .catch((e)=>{console.error('Error adding host', e)})

    sendEvent(EVENT_TYPES.HOST_JOINED_GAME, null, _gameId, playerId)
    firebase.analytics().logEvent('host__joined_game', {playerId: playerId, roomCode, gameId:_gameId, rejoin: isRejoiningHost})
    props.joinGameHandler(roomCode, _gameId, {id: playerId}, true)
  }

  async function joinPlayerToGame(gameDoc) {
    let _gameId = gameDoc.id
    let playerQuery = firebase.firestore().collection('games').doc(_gameId).collection('players').doc(playerId)

    let playerDoc = await playerQuery.get().catch((e)=>{console.error('Error querying for player', e)})


    let playerAlreadyExists = playerDoc.exists
    if (!playerAlreadyExists) {
      if (gameDoc.data().startedAt) {
        alert('Game has already started')
        return
      } else {
        // create player on game model
        let playerInfo = {
          id: playerId,
          name,
          font,
          score: 0,
          isBuzzedIn: false,
          hasGuessed: false,
        }
        playerQuery.set(playerInfo).catch((e)=>{console.error('Error adding player', e)})
      }
    }

    // TODO: this currently shows players their new input locally (bad).
    //       we should re-load their info from the firestore model
    // FOLLOW-UP NOTE: I think this means if they change their name it shows them
    //                the new one locally but not on the host (out of sync)
    let player = {
      id: playerId,
      name,
      font,
      score: 0,
      isBuzzedIn: false,
      hasGuessed: false
    }

    sendEvent(EVENT_TYPES.PLAYER_JOINED_GAME, null, _gameId, playerId)
    firebase.analytics().logEvent('player__joined_game', {playerId: playerId, roomCode, gameId:_gameId, rejoin: playerAlreadyExists})
    props.joinGameHandler(roomCode, _gameId, player, false)
  }

  async function cycleFont(reverse=false) {
    let _font = (+font+1)%NUM_FONTS
    if (reverse)
      _font = font === 0 ? NUM_FONTS-1 : +font-1

    localStorage.setItem('font', _font)
    firebase.analytics().logEvent('player__changed_font', {playerId: playerId, font: _font, isReverseSearch: false})
    setFont(_font)
  }

  let podiumProps = {
    isBuzzedIn: false,
    isTapping: isTappingBuzzer,
    score: 0,
    name: name.length ? name : '?',
    font: font,
    onContextMenuHandler: (e)=>{
      e.preventDefault();
    },
    onPointerDownHandler: ()=>{
      setIsTappingBuzzer(true)
    },
    onPointerUpHandler: ()=>{
      setIsTappingBuzzer(false)
    },
  }

  return (
    <div className="player-lobby">
      <Podium {...podiumProps} />
      <div className="player-lobby-podium-label">
        <button onClick={()=>{cycleFont(true)}}>{'<'}</button>
        <span> Change font! </span>
        <button onClick={()=>{cycleFont()}}>{'>'}</button>
      </div>
      <br/>
      <div className="player-lobby-input-section">
        <div>
          <div>
            <input ref={codeInput} className="player-lobby-room-code" placeholder="enter room code" maxLength={ROOM_CODE_LENGTH} value={roomCode} onChange={(e)=>{
              let value = e.target.value.toUpperCase().trim()
              localStorage.setItem('roomCode', value)
              setRoomCode(value)
            }}/>
          </div>
          <div>
            <input className="player-lobby-name" placeholder="enter name" maxLength={PLAYER_NAME_MAX_LENGTH} defaultValue={name} onChange={(e)=>{
              let value = e.target.value.trim()
              localStorage.setItem('name', value)
              setName(value)
            }}/>
          </div>
        </div>
        <div className="player-name-chars-left-container">
          <span className="player-name-chars-left">
            {PLAYER_NAME_MAX_LENGTH - name.length}
          </span>
        </div>
      </div>
      <div><button className="join-button" onClick={()=>{onJoinGameClick(false)}}>Join Game</button></div>
      <br/>
      <hr/>
      <br/>
      <div><a href="./?host" className="host-game-link">Host - new game board</a></div>
      <div><a href="#" className="host-game-link" onClick={()=>{onJoinGameClick(true)}}>Host - answer feed</a></div>
      <br/>
      <br/>
      <div><a href="./?changelog" className="changelog-link">changelog</a></div>
      {/* Pre-rendering fonts off-screen to elicit preloading and avoid pop-in */}
      <div style={{position: 'fixed', top: '-999999999px'}}>
        { (new Array(NUM_FONTS)).fill(0).map((v,i)=>{
          return <span key={i} className={`player-font-${i}`}>{i}</span>
        }) }
      </div>
    </div>
  );
}

export default PlayerClient;

