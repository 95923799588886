import '../css/Scores.css';
import React from 'react';
import Score from './Score'

function Scores(props) {
  let scores = Object.values(props.players).map((player, i)=>{
    return Score({
      gameId: props.gameId,
      player,
      question: props.question,
      adjustScore: props.adjustScore,
      isFinalJeopardy: props.isFinalJeopardy,
    })
  })

  return (
    <div className="podiums">
      { scores }
    </div>
  )
}

export default Scores;
