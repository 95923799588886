import React, { useState, useEffect } from 'react';
import { renderToString } from 'react-dom/server'
import Slide from './Slide'
import Podium from './Podium'
import '../css/HostLobby.css';
import { MAX_PLAYERS_ABSOLUTE } from '../constants'

import firebase from "firebase/app";
import "firebase/firestore";

let joinGameListener = ()=>{}

function HostLobby(props) {
  let [players, setPlayers] = useState([])

  useEffect(()=>{
    console.debug('starting listener')
    joinGameListener()
    let query = firebase.firestore().collection('games').doc(props.gameId).collection('players')
    console.debug('post query')

    joinGameListener = query.onSnapshot((snapshot)=>{
      console.debug('starting snapshot')
      let changes = snapshot.docChanges()

      let existingPlayerIds = players.map(v=>v.id)
      let _players = changes.map(change=>{
        if (change.type === "added") {
          let data = change.doc.data()
          return { id: data.id, name: data.name, font: data.font }
        }
        return null
      }).filter(v=>{
        return v !== null && !existingPlayerIds.includes(v.id)
      })
      if (players.length + _players.length >= MAX_PLAYERS_ABSOLUTE) {
        joinGameListener()
        let playersAllowed = MAX_PLAYERS_ABSOLUTE - players.length
        setPlayers([...players, ..._players.slice(0, playersAllowed)])
      } else if (_players.length) setPlayers([...players, ..._players])
    })
  },[players, props.gameId])

  function startGame() {
    firebase.analytics().logEvent('host__start_game_click', {gameId: props.gameId, roomCode: props.roomCode})
    if (!players.length) return
    joinGameListener()
    props.startGameHandler(players, props.gameId, props.roomCode)
  }

  let slide = Slide({
    html: renderToString(
      <div>
        <div>Go to <a href="http://polywots.com" target="_blank" rel="noreferrer">polywots.com</a><br/><br/>enter room code:</div>
        <div className="host-lobby-code">{props.roomCode}</div>
      </div>
    ),
    onClick: ()=>{},
    onContextMenu: ()=>{},
  })

  let playerPodiums = players.map(player=>{
    let podiumProps = {
      isBuzzedIn: false,
      score: 0,
      name: player.name,
      font: player.font,
      onClickHandler: ()=>{},
      onContextMenuHandler: (e)=>{
        // TODO: re-add removing players from lobby (will also need to remove from model)
        // e.preventDefault()

        // // remove player from game
        // let _players = players.filter(v=>v.id !== player.id)
        // setPlayers(_players)
      },
    }
    return (<Podium key={player.id} {...podiumProps} />)
  })

  return (
    <div className="host-lobby">
      {slide}
      <div><button style={{'margin':'5px'}} onClick={startGame}>Start Game</button></div>
      <div className="podiums">
        {playerPodiums}
      </div>
      {/* Pre-rendering fonts off-screen to elicit preloading and avoid pop-in */}
      <div style={{position: 'fixed', top: '-999999999px'}}>
        {
          [
            'font-itc-korinna',
            'font-swiss-911-extra',
            'font-swiss-911',
            'font-univers-75-black',
          ].map((v,i)=>{return <span key={i} className={v}>{i}</span>})
       }
      </div>
    </div>
  )
}

export default HostLobby;
