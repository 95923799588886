import '../css/Slide.css';

function Slide(props) {
  return (
    <div className="slide" onClick={props.onClick} onContextMenu={(e)=>{e.preventDefault();props.onContextMenu()}}>
      { props.html ?
        <div className="slide-text" dangerouslySetInnerHTML={{__html: props.html}}></div>
        :
        <div className="slide-text">{props.text}</div>
      }
    </div>
  )
}

export default Slide