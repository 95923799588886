import '../css/Score.css';
import React, { useState, useMemo } from 'react';
import Podium from './Podium'

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

function Score(props) {
  let [delta, setDelta] = useState(0)
  let hideInput = !(props.isFinalJeopardy || (props.question && props.question.daily_double))

  useMemo(() => {
    if (props.question && props.question.value) {
      let questionValue = +props.question.value.replace(/[^0-9]/g,'')
      setDelta(questionValue)
    }
  }, [props.question])

  let podiumProps = {
    isBuzzedIn: props.player.isBuzzedIn,
    score: props.player.score,
    name: props.player.name,
    font: props.player.font,
    onClickHandler: ()=>{
      firebase.analytics().logEvent('host__podium_clicked', {gameId: props.gameId, questionId: props.question?.id, playerId: props.player.id})
      props.adjustScore(props.player.id, +delta)
    },
    onContextMenuHandler: ()=>{
      firebase.analytics().logEvent('host__podium_right_clicked', {gameId: props.gameId, questionId: props.question?.id, playerId: props.player.id})
      props.adjustScore(props.player.id, -delta)
    },
  }

  return (
    <div className="score" key={props.player.id}>
      <Podium {...podiumProps} />
      <div className="score-controls-container">
        <div className="score-controls">
          <div>
            <input className={`score-input ${hideInput ? 'hidden' : ''}`}
                  value={delta}
                  onChange={(e)=>setDelta(e.target.value)}
            />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Score;
