import React from 'react';
import HostClient from './HostClient'
import PlayerClient from './PlayerClient'
import Changelog from './Changelog'
import '../css/App.css';

// import {HOST_CODES} from '../constants.js'
// import firebase from "firebase/app";
// import "firebase/analytics";

function App() {
  let isAllowedToHost = true
  // let isAllowedToHost = false
  // let hostCode = localStorage.getItem('hostCode')
  // NOTE: temporarily dropping this since it frustrates people (mom, monica, new hosts)
  // if (hostCode) {
  //   // attempting to host
  //   // console.debug('attempting to host with code '+hostCode)

  //   let hostName = HOST_CODES[hostCode]
  //   firebase.analytics().logEvent('host__hosting_attempt', { hostName, hostCode })
  //   if (hostName) {
  //     console.debug(`matched hostCode with hostName: ${hostName}`)

  //     isAllowedToHost = true
  //   }
  // }

  let isHosting = document.location.search.slice(1) === 'host'
  let isChangelog = document.location.search.slice(1) === 'changelog'
  return (
    <div className='app' onContextMenu={(e)=>e.preventDefault()}>
      { isChangelog ? <Changelog/> : (isHosting && isAllowedToHost ? <HostClient/> : <PlayerClient/>) }
    </div>
  )
}

export default App;

