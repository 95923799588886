import React, { useState, useMemo, useCallback } from 'react';
import Board from './Board'
import Slide from './Slide'
import Scores from './Scores'
import '../css/Game.css';
import { renderToString } from 'react-dom/server'
import {EVENT_TYPES, sendEvent, sendHostInfoEvent} from '../GameEvent'
import {FINAL_JEOPARDY_TIMER} from '../constants'

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

let buzzInListener = ()=>{};
let finalAnswerListener = ()=>{};
let finalJeopardyTimeout

function Game(props) {
  const getPlayersInit = useCallback(() => {
    let playersInit = {}
    props.players.forEach(player=>{
      playersInit[player.id] = { id: player.id, name: player.name, font: player.font, score: 0, isBuzzedIn: false, hasGuessed: false }
    })
    return playersInit
  }, [props.players])

  let [show, setShow] = useState(props.show)
  let [round, setRound] = useState(show.rounds.jeopardy)
  let [question, setQuestion] = useState(null)
  let [canBuzzIn, setCanBuzzIn] = useState(false)
  let [players, setPlayers] = useState(getPlayersInit)
  let [activeSlide, setActiveSlide] = useState(null)

  useMemo(()=>{
    // this updates if the game file is changed
    sendEvent(EVENT_TYPES.GAME_RESET, props.show.id, props.gameId)

    // FIXME: hack to reset questions, because we track questions disabled on props
    Object.entries(props.show.rounds).forEach(data=>{
      let [roundName, round] = data
      Object.entries(round.questions).forEach(v=>{
        let [category, questions] = v
        questions.forEach((question,i)=>{
          props.show.rounds[roundName].questions[category][i].disabled = false
        })
      })
    })

    firebase.analytics().logEvent('host__game_initialized', {gameId: props.gameId})
    setShow(props.show)
    setRound(props.show.rounds.jeopardy)
    setQuestion(null)
    setActiveSlide(null)
    setCanBuzzIn(null)
    setPlayers(getPlayersInit())
  },[getPlayersInit, props.show, props.gameId])

  // setting intro slides after "reset" behavior above renders for the first time
  useState(()=>{
    let introSlide = Slide({
      html: renderToString(<i style={{'fontSize':'140px'}}>JEOPARDY!</i>),
      onClick: ()=>{
        props.audio.intro.pause()
        props.audio.boardFill.play()
        setActiveSlide(null)
      },
      onContextMenu: ()=>{},
    })
    props.audio.intro.play()

    setActiveSlide(introSlide)
  })


  function isDailyDouble() {
    return question && question.daily_double
  }
  function isFinalJeopardy(_round=round) {
    return _round.round === 'finalJeopardy'
  }

  function onQuestionClick(event, _question) {
    firebase.analytics().logEvent('host__question_clicked', {gameId: props.gameId, questionId: _question.id})
    // FIXME: hack - should not be editing props
    if (!_question.question)
      return

    if (event.ctrlKey) {
      _question.disabled = false
      setQuestion(_question)
      return
    }
    _question.disabled = true
    setQuestion(_question)

    let questionSlide = Slide({
      html: _question.question,
      onClick: ()=>{
        firebase.analytics().logEvent('host__question_read', {gameId: props.gameId, questionId: _question.id})
        sendEvent(EVENT_TYPES.QUESTION_READ, _question.id, props.gameId)
        if (!_question.daily_double)
          listenForBuzzers()
      },
      onContextMenu: ()=>{clearQuestion()},
    })

    if (_question.daily_double) {
      let dailyDoubleSlide = Slide({
        html: renderToString(<u>DAILY DOUBLE!</u>),
        onClick: ()=>{
          sendEvent(EVENT_TYPES.QUESTION_OPENED, { questionId: _question.id, dailyDouble: true }, props.gameId)
          setActiveSlide(questionSlide)
        },
        onContextMenu: ()=>{clearQuestion()},
      })
      props.audio.dailyDouble.play()
      setActiveSlide(dailyDoubleSlide)
    } else {
    sendEvent(EVENT_TYPES.QUESTION_OPENED, { questionId: _question.id, dailyDouble: false }, props.gameId)
    setActiveSlide(questionSlide)
    }

    sendHostInfoEvent(`[Answer] ${_question.answer}`, props.gameId)
  }

  function clearQuestion() {
    let questionId = question ? question.id : null
    firebase.analytics().logEvent('host__question_cleared', {gameId: props.gameId, questionId: questionId})

    // FIXME: sends event when round changed if no question is up,
    //        can't rely on validity of 'question' state var here to check
    sendEvent(EVENT_TYPES.QUESTION_CLOSED, questionId, props.gameId)
    setQuestion(null)
    setCanBuzzIn(false)
    setActiveSlide(null)
    Object.keys(players).forEach(player=>{
      setPlayerValue(player, 'isBuzzedIn', false)
      setPlayerValue(player, 'hasGuessed', false)
    })

    buzzInListener()
  }

  function setPlayerValue(id, property, value) {
    if (!players[id]) return false
    let _players = {...players}
    _players[id][property] = value
    setPlayers(_players)
  }

  function adjustScore(id, delta) {
    if (!question || !players[id]) return false
    let newScore = players[id].score + delta
    let questionId = question ? question.id : null
    sendEvent(EVENT_TYPES.PLAYER_SCORE_CHANGED, newScore, props.gameId, id)
    sendEvent(EVENT_TYPES.PLAYER_GUESSED, questionId, props.gameId, id)
    setPlayerValue(id, 'score', newScore)
    setPlayerValue(id, 'isBuzzedIn', false)
    if (delta < 0 && question && !question.daily_double)
      listenForBuzzers()
  }

  function changeRound(_round, isUserClick = false) {
    if (isUserClick) firebase.analytics().logEvent('host__change_round_click', {gameId: props.gameId, round: _round.round})
    clearQuestion()
    props.audio.finalJeopardy.pause()
    props.audio.finalJeopardy.currentTime = 0

    // Final Jeopardy
    if (isFinalJeopardy(_round)) {
      firebase.analytics().logEvent('host__final_jeopardy_opened_category', {gameId: props.gameId})
      sendEvent(EVENT_TYPES.FINAL_JEOPARDY_OPENED_CATEGORY, null, props.gameId)
      // if moving into final jeopardy
      let data = _round.questions[_round.categories[0]][0]
      let finalCategory = Slide({
        html: data.category,
        onClick: ()=>{
          firebase.analytics().logEvent('host__final_jeopardy_opened_question', {gameId: props.gameId})
          sendEvent(EVENT_TYPES.FINAL_JEOPARDY_OPENED_QUESTION, null, props.gameId)
          sendHostInfoEvent(`[Answer] ${data.answer}`, props.gameId)
          setQuestion(data)
          setActiveSlide(finalQuestion)
          listenForFinalAnswers(false)
        },
        onContextMenu: ()=>{
          firebase.analytics().logEvent('host__final_jeopardy_exited', {gameId: props.gameId, isGameEnded: false})
          changeRound(show.rounds.doubleJeopardy)
          clearQuestion()
          finalAnswerListener()
        },
      })

      let finalQuestion = Slide({
        html: renderToString(
          <div>
            <div className='slide-header'>{data.category}</div>
            <div>{data.question}</div>
          </div>
        ),
        onClick: ()=>{
          firebase.analytics().logEvent('host__final_jeopardy_read', {gameId: props.gameId})
          sendEvent(EVENT_TYPES.FINAL_JEOPARDY_READ, null, props.gameId)
          props.audio.finalJeopardy.play()
          console.log('--Final Jeopardy Timer Started--')
          finalJeopardyTimeout = setTimeout(()=>{
            firebase.analytics().logEvent('host__final_jeopardy_ended', {gameId: props.gameId})
            sendEvent(EVENT_TYPES.FINAL_JEOPARDY_ENDED, null, props.gameId)
            console.log('--Final Jeopardy Timer Ended--')
          }, FINAL_JEOPARDY_TIMER)
        },
        onContextMenu: ()=>{
          // game finished
          clearTimeout(finalJeopardyTimeout)
          firebase.analytics().logEvent('host__final_jeopardy_exited', {gameId: props.gameId, isGameEnded: true})
          props.audio.finalJeopardy.pause()
          props.audio.finalJeopardy.currentTime = 0
          clearQuestion()
          finalAnswerListener()
          changeRound(show.rounds.doubleJeopardy)
        },
      })

      listenForFinalAnswers(true)
      setActiveSlide(finalCategory)
    } else {
      props.audio.boardFill.play()
      if (isFinalJeopardy(round)){
        // if moving out of final jeopardy
        finalAnswerListener()
      }
    }
    setRound(_round)
  }

  function listenForFinalAnswers(betsOnly=false) {
    finalAnswerListener()
    if (betsOnly) console.log('--Final Jeopardy Betting Started--')
    else console.log('--Final Jeopardy Betting Ended--')
    console.debug('final jeopardy for gameId',props.gameId)

    let event = betsOnly ? EVENT_TYPES.PLAYER_FINAL_BET : EVENT_TYPES.PLAYER_FINAL_ANSWER
    let query = firebase.firestore().collection('games').doc(props.gameId).collection('events')
                .where('type', '==', event)
                .where('createdAt', '>', firebase.firestore.Timestamp.fromDate(new Date()))
                .orderBy('createdAt', 'asc')

    finalAnswerListener = query.onSnapshot((snapshot)=>{
      let changes = snapshot.docChanges()
      for (let i=0;i<changes.length;i++) {
        console.debug(`reviewing event changes for ${event}`)
        let data = changes[i].doc.data()
        let id = data.playerId

        if (!!players[id]) {
          if (betsOnly) {
            firebase.analytics().logEvent('host__final_bet_received', {gameId: props.gameId, playerId: id})
            sendHostInfoEvent(`[Final Bet] ${players[id].name} - $${data.data}`, props.gameId)
          }
          else {
            firebase.analytics().logEvent('host__final_answer_received', {gameId: props.gameId, playerId: id})
            sendHostInfoEvent(`[Final Answer] ${players[id].name} - ${data.data}`, props.gameId)
          }
        }
      }
    })
  }

  function listenForBuzzers() {
    console.debug('listenForBuzzers - start')
    // already checking, or no need to
    let isPlayerBuzzedIn = Object.values(players).some(v=>v.isBuzzedIn)
    if (isPlayerBuzzedIn || canBuzzIn || isDailyDouble() || isFinalJeopardy()) return false
    buzzInListener()
    console.debug('listenForBuzzers - past killswitches')

    setCanBuzzIn(true)
    let query = firebase.firestore().collection('games').doc(props.gameId).collection('events')
                .where('type', '==', EVENT_TYPES.PLAYER_PRESSED_BUZZER)
                .where('createdAt', '>', firebase.firestore.Timestamp.fromDate(new Date()))
                .orderBy('createdAt', 'asc')

    console.debug('listenForBuzzers - pre-query execution')
    buzzInListener = query.onSnapshot((snapshot)=>{
      console.debug('listenForBuzzers - in listener callback')
      let changes = snapshot.docChanges()
      console.debug('listenForBuzzers - doc changes: ',changes)
      for (let i=0;i<changes.length;i++) {
        let data = changes[i].doc.data()
        console.debug('listenForBuzzers - single change data: ',data)
        let id = data.playerId

        // player is in game and has not guessed on this question yet
        console.debug('listenForBuzzers - players: ',players)
        console.debug('listenForBuzzers - !!players[id]: ',!!players[id],', !players[id].hasGuessed: ',!players[id].hasGuessed)
        if (!!players[id] && !players[id].hasGuessed) {
          console.debug('listenForBuzzers - REGISTERED BUZZ-IN: ',players[id].name)
          let questionId = question ? question.id : null
          firebase.analytics().logEvent('host__player_buzzed_in', {gameId: props.gameId, playerId: id, questionId: questionId})
          sendEvent(EVENT_TYPES.PLAYER_BUZZED_IN, questionId, props.gameId, id)
          setPlayerValue(id, 'isBuzzedIn', true)
          setPlayerValue(id, 'hasGuessed', true)
          buzzInListener()
          setCanBuzzIn(false)
          return
        }
      }
    })
  }

  function timerClicked() {
    let questionId = question ? question.id : null
    firebase.analytics().logEvent('host__question_ended', {gameId: props.gameId, questionId: questionId})
    sendEvent(EVENT_TYPES.QUESTION_ENDED, questionId, props.gameId)
    buzzInListener()
    props.audio.timer.play()
  }

  return (
    <div className="game">
      <div>
        { activeSlide ? activeSlide : <Board {...{round, onQuestionClick}} /> }
        <div className="game-info">
          {props.roomCode}, {show.id}{question ? '-'+question.id : null}<br/>
          {show.airDate}<br/>
          <button className="game-timer-button" onClick={timerClicked}>Time's up!</button>
        </div>
      </div>
      <Scores {...{gameId: props.gameId, question, players, adjustScore, isFinalJeopardy: isFinalJeopardy()}} />
      <div className="round-controls">
        <button onClick={()=>{changeRound(show.rounds.jeopardy, true)}}>Jeopardy!</button>&nbsp;
        <button onClick={()=>{changeRound(show.rounds.doubleJeopardy, true)}}>Double Jeopardy!</button>&nbsp;
        <button onClick={()=>{changeRound(show.rounds.finalJeopardy, true)}}>Final Jeopardy!</button>
      </div>
    </div>
  )
}

export default Game;
