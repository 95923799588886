import firebase from "firebase/app";
import "firebase/firestore";

export const EVENT_TYPES = {
  HOST_JOINED_GAME: 'HOST_JOINED_GAME',
  PLAYER_JOINED_GAME: 'PLAYER_JOINED_GAME',
  PLAYER_PRESSED_BUZZER: 'PLAYER_PRESSED_BUZZER',
  PLAYER_BUZZED_IN: 'PLAYER_BUZZED_IN',
  PLAYER_SCORE_CHANGED: 'PLAYER_SCORE_CHANGED',
  PLAYER_GUESSED: 'PLAYER_GUESSED',
  PLAYER_FINAL_BET: 'PLAYER_FINAL_BET',
  PLAYER_FINAL_ANSWER: 'PLAYER_FINAL_ANSWER',
  QUESTION_OPENED: 'QUESTION_OPENED',
  QUESTION_READ: 'QUESTION_READ',
  QUESTION_ENDED: 'QUESTION_ENDED',
  QUESTION_CLOSED: 'QUESTION_CLOSED',
  FINAL_JEOPARDY_OPENED_CATEGORY: 'FINAL_JEOPARDY_OPENED_CATEGORY',
  FINAL_JEOPARDY_OPENED_QUESTION: 'FINAL_JEOPARDY_OPENED_QUESTION',
  FINAL_JEOPARDY_READ: 'FINAL_JEOPARDY_READ',
  FINAL_JEOPARDY_ENDED: 'FINAL_JEOPARDY_ENDED',
  GAME_STARTED: 'GAME_STARTED',
  GAME_ENDED: 'GAME_ENDED',
  GAME_RESET: 'GAME_RESET',
}

export function sendHostInfoEvent(text, gameId) {
  console.info(text)
  firebase.firestore().collection('games').doc(gameId).collection('hostInfoEvents').add({
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    'text':text
  }).catch((e)=>{console.error('Error sending host info event', e)})
}

export function sendEvent(type, data, gameId, playerId=null) {
  firebase.firestore().collection('games').doc(gameId).collection('events').add({
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    playerId,
    type,
    data,
  }).catch((e)=>{console.error('Error sending game event', e)})

  updateModel(type, data, gameId, playerId)
}

// keeps base model in sync with events, so in the case of a disconnect a player that rejoins will have the correct information
async function updateModel(type, data, gameId, playerId=null) {
  let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()
  let snapshot
  switch (type) {
    case EVENT_TYPES.GAME_STARTED:
      firebase.firestore().collection('games').doc(gameId).update({startedAt: serverTimestamp})
        .catch((e)=>{console.error('Error updating model', e)})
      break;
    case EVENT_TYPES.GAME_ENDED:
      firebase.firestore().collection('games').doc(gameId).update({endedAt: serverTimestamp})
        .catch((e)=>{console.error('Error updating model', e)})
      break;
    case EVENT_TYPES.GAME_RESET:
      snapshot = await firebase.firestore().collection('games').doc(gameId).collection('players').get()
        .catch((e)=>{console.error('Error fetching players', e)})
      snapshot.docs.forEach(doc=>{
        firebase.firestore().collection('games').doc(gameId).collection('players').doc(doc.id).update({isBuzzedIn: false, hasGuessed: false, score: 0})
          .catch((e)=>{console.error('Error updating model', e)})
      })
      break;
    case EVENT_TYPES.PLAYER_SCORE_CHANGED:
      firebase.firestore().collection('games').doc(gameId).collection('players').doc(playerId).update({score: data})
        .catch((e)=>{console.error('Error updating model', e)})
      break;
    case EVENT_TYPES.PLAYER_BUZZED_IN:
      firebase.firestore().collection('games').doc(gameId).collection('players').doc(playerId).update({isBuzzedIn: true})
        .catch((e)=>{console.error('Error updating model', e)})
      break;
    case EVENT_TYPES.PLAYER_GUESSED:
      firebase.firestore().collection('games').doc(gameId).collection('players').doc(playerId).update({hasGuessed: true})
        .catch((e)=>{console.error('Error updating model', e)})
      break;
    case EVENT_TYPES.QUESTION_CLOSED:
      snapshot = await firebase.firestore().collection('games').doc(gameId).collection('players').get()
        .catch((e)=>{console.error('Error fetching players', e)})
      snapshot.docs.forEach(doc=>{
        firebase.firestore().collection('games').doc(gameId).collection('players').doc(doc.id).update({isBuzzedIn: false, hasGuessed: false})
          .catch((e)=>{console.error('Error updating model', e)})
      })
      break;
    default: break;
  }
}
