import React from 'react';
import BoardTile from './BoardTile'
import '../css/Board.css';

function Board(props) {
  let categoryTiles = props.round.categories.map(category=>{
    return BoardTile({
      value: category,
      key: category,
      category: true
    })
  })
  if (props.round.categories.length < 6) {
    categoryTiles.push(BoardTile({
      value: '',
      key: 'empty',
      category: true
    }))
  }

  let questions = []

  let isFinalJeopardy = props.round.round === 'finalJeopardy'

  if (!isFinalJeopardy){
    for (let i=0; i<5; i++) {
      let row = props.round.categories.map(category=>{
        let q = props.round.questions[category][i]
        return BoardTile({
          value: q.value,
          key: `q-${category}-${i}`,
          disabled: q.disabled,
          onClick: (e)=>{props.onQuestionClick(e,q)}
        })
      })
      if (props.round.categories.length < 6) {
        row.push(BoardTile({
          value: '',
          key: `q-empty-${i}`,
          category: true
        }))
      }
      questions = [...questions, ...row]
    }
  }

  return (
    <div className="board">
      <div className="row category-row">{categoryTiles}</div>
      <div className="row">{questions.slice(0,6)}</div>
      <div className="row">{questions.slice(6,12)}</div>
      <div className="row">{questions.slice(12,18)}</div>
      <div className="row">{questions.slice(18,24)}</div>
      <div className="row last-row">{questions.slice(24)}</div>
    </div>
  )
}

export default Board;
