import '../css/Podium.css';
import React from 'react';

function Podium(props) {
  function formatScore(score) {
    let textScore = [...`${Math.abs(score)}`].map((v, i, nums) => (!i || (nums.length-i)%3 ? '' : ',') + v).join('')
    return (props.score < 0 ? '-$' : '$') + textScore
  }

  let podiumClasses = [
    'podium',
    props.isPlayerBuzzer ? 'podium-buzzer' : '',
    props.isTapping ? 'is-tapping' : '',
    props.isBuzzedIn && !props.hasGuessed ? 'buzzed-in' : '',
    // props.hasGuessed ? 'has-guessed' : '',
  ]

  return (
    <div className={podiumClasses.join(' ')}
      onClick={props.onClickHandler}
      onContextMenu={props.onContextMenuHandler}
      onPointerDown={props.onPointerDownHandler}
      onPointerUp={props.onPointerUpHandler}
      onPointerLeave={props.onPointerUpHandler}
    >
      <div className={`score-value ${props.score < 0 ? 'score-value-negative' : ''}`}>
        <div className='score-value-text'>{formatScore(props.score)}</div>
      </div>
      <div className='score-name'>
        <div className={`score-name-text player-font-${props.font}`}>{props.name}</div>
      </div>
    </div>
  )
}

export default Podium;
