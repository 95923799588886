import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App.jsx';

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';

import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/performance";
// import {getCLS, getFID, getLCP} from 'web-vitals';

import { hotjar } from 'react-hotjar';

import './css/index.css';

// SENTRY
Sentry.init({
  dsn: "https://96a38c2cf00449dbadb266a4ed6be23c@o527963.ingest.sentry.io/5644927",
  integrations: [
    new Integrations.BrowserTracing(),
    new CaptureConsole({
      levels: ['error']
    }),
  ],

  // We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: 1.0,
})

// FIREBASE
const firebaseConfig = {
  apiKey: "AIzaSyDZZ2OOS9xBM4Ky17G5ih_-F7sdM9m5G44",
  authDomain: "trivia-78b13.firebaseapp.com",
  projectId: "trivia-78b13",
  storageBucket: "trivia-78b13.appspot.com",
  messagingSenderId: "764438551071",
  appId: "1:764438551071:web:64e9ad59d2b4a748b3d32a",
  measurementId: "G-Y3BRFV7Y0P"
}
firebase.initializeApp(firebaseConfig)
firebase.performance()
firebase.analytics()

// firebase performance monitoring - first input delay polyfill
// https://firebase.google.com/docs/perf-mon/get-started-web?authuser=0#add-first-input-delay-polyfill
// getCLS(console.debug);
// getFID(console.debug);
// getLCP(console.debug);

hotjar.initialize(2258399, 6);

ReactDOM.render(
  <App />,
  document.getElementById('root')
)

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// )
