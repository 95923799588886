export const ROOM_CODE_LENGTH = 4
export const PLAYER_NAME_MAX_LENGTH = 12
export const MAX_PLAYERS_DEFAULT = 7 // one row below main content
export const MAX_PLAYERS_ABSOLUTE = 14 // two rows below main content
export const NUM_FONTS = 20

// timers
export const FINAL_JEOPARDY_TIMER = 31 * 1000 //ms

export const BUZZER_RATE_LIMIT = 200 //ms
//  Dez average 150ms on mouse (fast)
//  Monica average 180ms on mouse

export const HOST_CODES = {
  'KtTl54p$^g%#': 'Dez',
  'h7q#$QpDs%#0': 'Monica',
  'UPMJjp4#Jqu2': 'Dana',
  'rtUb^R5L7z8n': 'Mom',
  'h6T9E4QdRu^!': 'Courtney',
  'm$kQ1iG5Ke6I': 'UNUSED_1',
  'h#903BFnqZ44': 'UNUSED_2',
  '4XP*0pN02Co%': 'UNUSED_3',
  'jmZ!X$887*qd': 'UNUSED_4',
  'Z*s9w1*9D4CS': 'UNUSED_5',
  'i3iV&*f9J4S!': 'UNUSED_6',
  'je#Q2!*Fg4^V': 'UNUSED_7',
  'dXX5d8^gAP%B': 'UNUSED_8',
  'j5A4eZq$UXk^': 'UNUSED_9',
  'gU7pB9It7X*P': 'UNUSED_10',
}
