import '../css/markdown.css';

import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';

function Changelog(props) {
  const [content, setContent] = useState('');

  useEffect(() => {
    fetch('/changelog.md')
      .then((response) => response.text())
      .then((data) => setContent(data))
      .catch((error) => console.error('Error:', error));
  }, []);

  return (
    <div className="markdown-container">
      <ReactMarkdown>{content}</ReactMarkdown>
    </div>
  )

}

export default Changelog;
