import React, { useState, useEffect } from 'react';
import '../css/Buzzer.css';
import {EVENT_TYPES, sendEvent} from '../GameEvent'
import {BUZZER_RATE_LIMIT} from '../constants'
import Podium from './Podium'

import firebase from "firebase/app";
import "firebase/analytics";

// let allTimes = []

let playerInfoEventListener = ()=>{};

function Buzzer(props) {
  let [player, setPlayer] = useState(()=>{return{...props.player}})
  let [lastBuzzedIn, setLastBuzzedIn] = useState(0)
  let [finalBet, setFinalBet] = useState(0)
  let [finalAnswer, setFinalAnswer] = useState('')
  let [showFinalBetSuccess, setShowFinalBetSuccess] = useState(false)
  let [showFinalAnswerSuccess, setShowFinalAnswerSuccess] = useState(false)
  let [isTappingBuzzer, setIsTappingBuzzer] = useState(false)

  // handles updating buzzer for player on their phone, kept in sync with firebase
  useEffect(()=>{
    playerInfoEventListener()

    console.debug('listenForPlayerEvents - start!')
    console.debug('listenForPlayerEvents - gameid', props.gameId)
    let query = firebase.firestore().collection('games').doc(props.gameId).collection('players').where('id', '==', props.player.id)
    console.debug('listenForPlayerEvents - player id', props.player.id)

    console.debug('listenForPlayerEvents - pre-query execution')
    playerInfoEventListener = query.onSnapshot((snapshot)=>{
      console.debug('playerInfoEventListener - in listener callback')
      let changes = snapshot.docChanges()
      if (changes.length) {
        let updatedPlayer = changes[0].doc.data()
        console.debug('playerInfoEventListener - doc props.player: ',props.player)
        console.debug('playerInfoEventListener - doc updatedPlayer: ',updatedPlayer)
        setPlayer({...updatedPlayer})
      }
    })
  }, [props.gameId, props.player])


  function handleBuzzerClick() {
    console.debug('handleBuzzerClick - doc props.player: ',props.player)
    console.debug('handleBuzzerClick - doc player: ',player)
    let now = Date.now()

    let timeSinceLast = now - lastBuzzedIn
    let hitRateLimit = lastBuzzedIn + BUZZER_RATE_LIMIT > now
    console.debug(`time since last buzz-in: ${timeSinceLast}ms (${hitRateLimit ? 'FAILED' : 'passed'})`)
    console.debug('player__buzzer_click', {gameId: props.gameId, playerId: props.player.id, timeSinceLast, hitRateLimit})
    firebase.analytics().logEvent('player__buzzer_click', {gameId: props.gameId, playerId: props.player.id, timeSinceLast, hitRateLimit})

    // if (lastBuzzedIn !== 0) allTimes.push(timeSinceLast)
    // let avgTime = allTimes.reduce((a,v)=>a+v,0)/allTimes.length
    // console.debug(`time since last buzz-in: ${timeSinceLast}ms and a ${avgTime}ms average (${hitRateLimit ? 'FAILED' : 'passed'})`)

    if (hitRateLimit) return
    setLastBuzzedIn(now)
    sendEvent(EVENT_TYPES.PLAYER_PRESSED_BUZZER, null, props.gameId, props.player.id)
  }

  function sendBet(e) {
    e.preventDefault();
    firebase.analytics().logEvent('player__sent_final_bet', {gameId: props.gameId, playerId: props.player.id, finalBet})
    if (finalBet < 0 || finalBet%1 !== 0) {
      alert('Bets must be non-negative integer values')
      return
    }

    sendEvent(EVENT_TYPES.PLAYER_FINAL_BET, finalBet, props.gameId, props.player.id)
    setShowFinalBetSuccess(true)
    setTimeout(()=>{
      setShowFinalBetSuccess(false)
    }, 3000)
  }

  function sendFinalAnswer(e) {
    e.preventDefault();
    firebase.analytics().logEvent('player__sent_final_answer', {gameId: props.gameId, playerId: props.player.id, finalAnswer})
    sendEvent(EVENT_TYPES.PLAYER_FINAL_ANSWER, finalAnswer, props.gameId, props.player.id)
    setShowFinalAnswerSuccess(true)
    setTimeout(()=>{
      setShowFinalAnswerSuccess(false)
    }, 3000)
  }

  let podiumProps = {
    isPlayerBuzzer: true,
    isTapping: isTappingBuzzer,
    isBuzzedIn: player.isBuzzedIn,
    hasGuessed: player.hasGuessed,
    score: player.score,
    name: player.name,
    font: player.font,
    onContextMenuHandler: (e)=>{
      e.preventDefault();
    },
    onPointerDownHandler: ()=>{
      setIsTappingBuzzer(true)
      handleBuzzerClick()
    },
    onPointerUpHandler: ()=>{
      setIsTappingBuzzer(false)
    },
  }

  return (
    <div className="buzzer">
      <div>In Game: {props.roomCode}</div>
      <div className="buzzer-podium-container">
        <Podium {...podiumProps} />
      </div>
      <div className="buzzer-final-jeopardy">
        <h3><u>Final Jeopardy ONLY</u></h3>
        <input className="buzzer-final-jeopardy-bet" type="number" placeholder="final jeopardy bet" onChange={(e)=>setFinalBet(+e.target.value)} />
        <button className="buzzer-lock-bet-button" onClick={sendBet}>Send Bet</button>
        <span className={`text-success final-jeopardy-success ${ !showFinalBetSuccess ? 'hidden' : ''}`}>Final Bet Sent!</span>
        <br/>
        <input className="buzzer-final-jeopardy-answer" placeholder="final jeopardy answer" onChange={(e)=>setFinalAnswer(e.target.value.trim())} />
        <button className="buzzer-send-answer-button" onClick={sendFinalAnswer}>Send Final Answer</button>
        <span className={`text-success final-jeopardy-success ${ !showFinalAnswerSuccess ? 'hidden' : ''}`}>Final Answer Sent!</span>
      </div>
    </div>
  );
}

export default Buzzer;

