import React, { useState, useEffect } from 'react';

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

let hostInfoEventListener = ()=>{};

function HostFeed(props) {
  let [hostEvents, setHostEvents] = useState([])

  useEffect(()=>{
    hostInfoEventListener()

    console.debug('listenForHostEvents - start!')
    console.debug('listenForHostEvents - gameid', props.gameId)
    let query = firebase.firestore().collection('games').doc(props.gameId).collection('hostInfoEvents').orderBy('createdAt', 'asc')

    console.debug('listenForHostEvents - pre-query execution')
    hostInfoEventListener = query.onSnapshot((snapshot)=>{
      console.debug('hostInfoEventListener - in listener callback')
      let changes = snapshot.docChanges()
      console.debug('hostInfoEventListener - doc changes: ',changes)

      let updates = changes.map(v=>v.doc.data().text).reverse()
      console.debug('hostInfoEventListener - updates: ',updates)
      setHostEvents(v=>([...updates, ...v].slice(0,20)))
    })
  }, [props.gameId])

  let hostEventsHtml = hostEvents.map((gameEvent, i)=>{
    return <div key={`game-event-${i}`}>{gameEvent}<br/></div>
  })

  return (
    <div className="host-feed">
      <h3>
        Host Feed
        <br/>
        <i>(20 most recent, new at the top)</i>
        <hr/>
      </h3>

      <div className="host-feed-events">
        { hostEventsHtml }
      </div>
    </div>
  );
}

export default HostFeed;

