import React, { useState, useMemo } from 'react';
import Game from './Game'
import HostLobby from './HostLobby'
import showDirectory from '../data/jeopardy_questions_directory'
import baseShow from '../data/shows/jeopardy_2012-01-25'
import '../css/HostClient.css';
import { v4 as uuid } from 'uuid';
import {EVENT_TYPES, sendEvent} from '../GameEvent'

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";

import {ROOM_CODE_LENGTH} from '../constants.js'

function generateCode(length=ROOM_CODE_LENGTH) {
  // 65-90 inclusive for capital letters
  let code = ''
  for (let i=0;i<length;i++) {
    let charCode = ~~(Math.random()*26)+65
    code += String.fromCharCode(charCode)
  }
  return code
}

const startVolume = localStorage.getItem('volume') ?? 100

function HostClient() {
  // let [shows, setShows] = useState(showDirectory.map(v=>v.date))
  let [shows, setShows] = useState(showDirectory)
  let [optFullGame, setOptFullGame] = useState(false)
  let [optNoMedia, setOptNoMedia] = useState(false)
  let [roomCode, setRoomCode] = useState(()=>{let id = generateCode(); localStorage.setItem('roomCode', id); return id})
  let [gameId, setGameId] = useState(uuid)
  let [show, setShow] = useState(()=>{
    // let randomShow = ~~(Math.random() * shows.length)
    // let airDate = shows[randomShow].date
    let airDate = shows[0].date
    import(`../data/shows/jeopardy_${airDate}`).then(json=>{
      changeShow(json, airDate)
    })
    return baseShow
  })
  let [isDashboardVisible, setIsDashboardVisible] = useState(false)
  let [players, setPlayers] = useState([])
  let [lobby, setLobby] = useState(<HostLobby {...{gameId, roomCode, startGameHandler}} />)
  let [audio] = useState(()=>{
    let _audio = {
      intro: new Audio('/audio/intro.mp3'),
      finalJeopardy: new Audio('/audio/finalJeopardy.mp3'),
      dailyDouble: new Audio('/audio/dailyDouble.mp3'),
      boardFill: new Audio('/audio/boardFill.mp3'),
      timer: new Audio('/audio/timer.mp3'),
    }

    Object.values(_audio).forEach(v=>{
      v.volume = startVolume/100
    })
    _audio.intro.volume *= .4
    _audio.dailyDouble.volume *= .25

    return _audio
  })

  // init
  function initGameModel(_roomCode=roomCode, _gameId=gameId) {
    console.debug('init')

    // TODO: should check if there is a lobby open with the game code already before setting it
    // 26^4 = 456,976 possible lobbies open at once

    // create firebase game model
    firebase.firestore().collection('games').doc(_gameId).set({
      roomCode: _roomCode,
      id: gameId,
      showId: show.id,
      airDate: show.airDate,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      startedAt: null,
      endedAt: null,
      // players
      // events
    }).catch((e)=>{
      console.error('Error creating firebase game model', e)
    })
  }
  useState(initGameModel)
  useState(()=>{
    firebase.analytics().setCurrentScreen('HostLobby')
  })

  // filter shows
  useMemo(()=>{
    let _shows = showDirectory.filter(v=>{
      let _fullGame = optFullGame ? v.full_game : true
      let _noMedia = optNoMedia ? !v.has_media : true
      return _fullGame && _noMedia
    })
    setShows(_shows)
  },[optFullGame, optNoMedia])

  function startGameHandler(_players, _gameId, _roomCode) {
    console.debug('startGameHandler:show',show)
    startGame(undefined, _players, _gameId, _roomCode)
  }

  // eslint-disable-next-line no-unused-vars
  function endGame() {
    firebase.analytics().logEvent('host__game_ended', {gameId, roomCode, showId: show.id})
    sendEvent(EVENT_TYPES.GAME_ENDED, show.id, gameId)

    // init new lobby
    let _roomCode = generateCode()
    let _gameId = uuid()
    localStorage.setItem('roomCode', _roomCode) // for ease of development

    initGameModel(_roomCode, _gameId)
    setLobby(<HostLobby {...{gameId:_gameId, roomCode:_roomCode, startGameHandler}} />)

    setRoomCode(_roomCode)
    setGameId(_gameId)
    setPlayers([])
  }

  function startGame(_show = show, _players = players, _gameId = gameId, _roomCode = roomCode) {
    console.debug('startGame:show',_show)
    firebase.analytics().logEvent('host__game_started', {gameId: _gameId, roomCode: _roomCode, showId: _show.id})
    sendEvent(EVENT_TYPES.GAME_STARTED, show.id, _gameId)

    // kill any lingering sound from previous game
    Object.values(audio).forEach(v=>v.pause())

    setPlayers(_players)
    firebase.analytics().setCurrentScreen('Game')
  }

  function toggleDashboard() {
    firebase.analytics().logEvent('host__toggle_dashboard_click', {gameId, roomCode, isShowing: !isDashboardVisible})
    setIsDashboardVisible(!isDashboardVisible)
  }

  // selects a random show if no airDate given
  function selectShow(airDate) {
    console.debug('host selected show')
    if (!airDate) {
      let randomShow = ~~(Math.random() * shows.length)
      airDate = shows[randomShow].date
    }
    firebase.analytics().logEvent('host__selected_show', {gameId, roomCode, airDate, uploaded: false})

    import(`../data/shows/jeopardy_${airDate}`).then(json=>{
      changeShow(json, airDate)
    })
  }

  function uploadShow(file) {
    if (!file) return
    console.debug('host uploaded show')
    firebase.analytics().logEvent('host__selected_show', {gameId, roomCode, airDate: null, uploaded: true})

    file.text().then((data)=>{
      let json = JSON.parse(data)
      changeShow(json)
    })
  }

  function changeShow(_show, airDate=null) {
    console.debug('host show - show loaded')
    firebase.analytics().logEvent('host__selected_show_loaded', {gameId, roomCode, showId: _show.id, airDate})

    // update firebase store with current show info
    firebase.firestore().collection('games').doc(gameId).update({
      showId: _show.id,
      airDate,
    }).catch((e)=>{
      console.error('Error updating firebase game model', e)
    })

    setShow(_show)
    if (players.length) startGame(_show)
  }

  function changeOptFullGame(value) {
    firebase.analytics().logEvent('host__changed_game_filter', {gameId, roomCode, settingsChanged: {optFullGame: value}})
    setOptFullGame(value)
  }

  function changeOptNoMedia(value) {
    firebase.analytics().logEvent('host__changed_game_filter', {gameId, roomCode, settingsChanged: {optNoMedia: value}})
    setOptNoMedia(value)
  }

  function updateVolume(value) {
    Object.values(audio).forEach(v=>{
      v.volume = value/100
    })
    audio.intro.volume *= .4
    audio.dailyDouble.volume *= .25
    localStorage.setItem('volume', value)
  }

  return (
    <div className="host-client">
      { players.length === 0 ? lobby : <Game {...{gameId, show, players, roomCode, audio}} />}
      <div className="controls">
        { isDashboardVisible ? (
          <div className="dashboard">
            <div>
              <button onClick={()=>{selectShow()}}>Load Random Show</button>
            </div>
            <div>
              <select className="game-select" onChange={(e)=>{selectShow(e.target.value)}}>
                {shows.map(v=>(<option value={v.date} key={v.date}>{v.date} ({v.show_id})</option>))}
              </select>
              <input type="checkbox" id="fullGame" name="fullGame" onChange={(e)=>changeOptFullGame(e.target.checked)} />
              <label htmlFor="fullGame">Full Game</label>
              <input type="checkbox" id="noMedia" name="noMedia" onChange={(e)=>changeOptNoMedia(e.target.checked)} />
              <label htmlFor="noMedia">No Media</label>
            </div>
            <div>
              <input type="file" onChange={(e)=>{uploadShow(e.target.files[0])}} />
            </div>
            <div>
              <label htmlFor="volume">Volume:</label>
              <input type="range" name="volume" min="0" max="100" defaultValue={startVolume} step="5" onChange={(e)=>updateVolume(e.target.value)}/>
            </div>
          </div>)
        : null }
        <button onClick={toggleDashboard}>Toggle Dashboard</button>
      </div>
    </div>
  )
}

export default HostClient;
