import React, { useState } from 'react';
import '../css/PlayerClient.css';
import PlayerLobby from './PlayerLobby'
import Buzzer from './Buzzer'
import HostFeed from './HostFeed'

import firebase from "firebase/app";
import "firebase/analytics";

function PlayerClient() {
  let [roomCode, setRoomCode] = useState(null)
  let [gameId, setGameId] = useState(null)
  let [player, setPlayer] = useState({ id: null, name: 'Player', font: 0, score: 0, isBuzzedIn: false, hasGuessed: false })
  let [isHost, setIsHost] = useState(false)

  useState(()=>{
    firebase.analytics().setCurrentScreen('PlayerLobby')
  })

  function joinGame(roomCode, gameId, _player, _isHost) {
    setRoomCode(roomCode)
    setGameId(gameId)
    setPlayer(_player)
    setIsHost(_isHost)

    if (_isHost) {
      firebase.analytics().setCurrentScreen('HostFeed')
    } else {
      firebase.analytics().setCurrentScreen('Buzzer')
    }
    firebase.analytics().setUserId(player.id)
  }

  function playerClientClick() {
    firebase.analytics().logEvent('player__client_click', {gameId, playerId: player.id})
  }

  return (
    <div className="player-client" onClick={playerClientClick}>
      { !gameId ?
        <PlayerLobby joinGameHandler={joinGame}/>
        :(
          isHost ?
          <HostFeed {...{roomCode, gameId, player}} />
          :
          <Buzzer {...{roomCode, gameId, player}} />
        )
      }
    </div>
  );
}

export default PlayerClient;

